import { RouteObject } from 'react-router-dom';

const route: RouteObject = {
	path: '',
	lazy: async () => ({ Component: (await import('@/modules/common/Layout.tsx')).default }),
	children: [
		{
			index: true,
			path: '',
			id: 'home',
			lazy: async () => ({ Component: (await import('@/modules/common/pages/Home')).default }),
		},
		{
			index: true,
			path: 'refundRequests',
			id: 'refundRequests',
			lazy: async () => ({ Component: (await import('@/modules/common/pages/RefundRequests.tsx')).default }),
		},
	],
};

export default route;
